// AdminDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
       const checkAdminAndFetchUsers = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/');
          return;
        }
    
        const userDoc = await getDoc(doc(db, 'customers', user.uid));
        const userData = userDoc.data();
        
        if (userData?.role !== 'admin') {
          navigate('/');
          return;
        }
    
        setIsAdmin(true);
        
        const usersCollection = collection(db, 'customers');
        const userSnapshot = await getDocs(usersCollection);
        const usersList = userSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(user => !user.email.endsWith('@servicegest.ro')); // Filter out users with @servicegest.ro
    
        setUsers(usersList);
        setFilteredUsers(usersList);
      } catch (error) {
        console.error('Error:', error);
        navigate('/');
      }
    };

    checkAdminAndFetchUsers();
  }, [navigate]);

  useEffect(() => {
    const results = users.filter(user =>
      Object.values(user).some(value => 
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  const handleSave = async () => {
    try {
      const userRef = doc(db, 'customers', editingUser.id);
      await updateDoc(userRef, {
        userName: editingUser.userName,
        userPhone: editingUser.userPhone,
        address: editingUser.address,
        startHour: editingUser.startHour,
        endHour: editingUser.endHour,
        progOnline: editingUser.progOnline
      });

      setUsers(users.map(user => user.id === editingUser.id ? editingUser : user));
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
  };

  const handleInputChange = (e) => {
    setEditingUser({ ...editingUser, [e.target.name]: e.target.value });
  };

  const handleProgOnlineChange = async (userId, currentValue) => {
    try {
      const userRef = doc(db, 'customers', userId);
      await updateDoc(userRef, {
        progOnline: !currentValue
      });

      setUsers(users.map(user => 
        user.id === userId ? { ...user, progOnline: !currentValue } : user
      ));
    } catch (error) {
      console.error('Error updating progOnline:', error);
    }
  };

  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert(`Password reset email sent to ${email}`);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert(`Error sending password reset email: ${error.message}`);
    }
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="flex flex-row min-h-screen bg-slate-900 text-white">
      {/* Mobile sidebar toggle */}

      {/* Sidebar */}
      <div className="w-64 bg-slate-800 shadow-md min-h-screen">
        <div className="p-4">
          <h2 className="text-xl font-bold">Admin Panel</h2>
        </div>
        <nav className="mt-4">
          <div className="px-4 py-2 hover:bg-purple-600 cursor-pointer">Dashboard</div>
          <div className="px-4 py-2 hover:bg-purple-600 cursor-pointer">Users</div>
          <div className="px-4 py-2 hover:bg-purple-600 cursor-pointer">Settings</div>
          <div 
            className="px-4 py-2 hover:bg-purple-600 cursor-pointer text-red-500"
            onClick={handleLogout}
          >
            Logout
          </div>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 p-4 md:p-8 overflow-auto">
        <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
        <div className="bg-slate-800 p-6 rounded-lg shadow-md mb-8">
          <h3 className="text-lg font-semibold mb-2">Total Users</h3>
          <p className="text-3xl font-bold">{users.length}</p>
        </div>

        {/* Search input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search users..."
            className="w-full p-2 border rounded bg-slate-800"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Users table */}
        <div className="bg-slate-800 rounded-lg shadow-md overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-slate-800">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Phone</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Address</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Hours</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Online Prog</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4">
                    {editingUser && editingUser.id === user.id ? (
                      <input
                        type="text"
                        name="userName"
                        value={editingUser.userName}
                        onChange={handleInputChange}
                        className="w-full p-1 border rounded"
                      />
                    ) : (
                      user.userName
                    )}
                  </td>
                  <td className="px-6 py-4">{user.email}</td>
                  <td className="px-6 py-4">
                    {editingUser && editingUser.id === user.id ? (
                      <input
                        type="text"
                        name="userPhone"
                        value={editingUser.userPhone}
                        onChange={handleInputChange}
                        className="w-full p-1 border rounded"
                      />
                    ) : (
                      user.userPhone
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {editingUser && editingUser.id === user.id ? (
                      <input
                        type="text"
                        name="address"
                        value={editingUser.address}
                        onChange={handleInputChange}
                        className="w-full p-1 border rounded"
                      />
                    ) : (
                      user.address
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {editingUser && editingUser.id === user.id ? (
                      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                        <input
                          type="time"
                          name="startHour"
                          value={editingUser.startHour}
                          onChange={handleInputChange}
                          className="w-full sm:w-20 p-1 border rounded"
                        />
                        <input
                          type="time"
                          name="endHour"
                          value={editingUser.endHour}
                          onChange={handleInputChange}
                          className="w-full sm:w-20 p-1 border rounded"
                        />
                      </div>
                    ) : (
                      `${user.startHour} - ${user.endHour}`
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={user.progOnline}
                        onChange={() => handleProgOnlineChange(user.id, user.progOnline)}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-slate-800 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </td>
                  <td className="px-6 py-4">
                    {editingUser && editingUser.id === user.id ? (
                      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                        <button
                          onClick={handleSave}
                          className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancel}
                          className="bg-gray-500 text-white px-2 py-1 rounded hover:bg-gray-600"
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                        <button
                          onClick={() => handleEdit(user)}
                          className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handlePasswordReset(user.email)}
                          className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600"
                        >
                          Reset Password
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;